.work-item .card {
    border: none;
    border-radius: initial;
    margin-bottom: 24px;
    border-bottom: 1px solid rgba(0,0,0,.125);
}

.work-item .swiper-container {
    width: 100%;
    height: 300px;
}

.work-item .swiper-slide {
    background-color: white;
    width: auto;
    height: 300px;
}

.work-item .swiper-slide img{
    padding: 10px;
    width: auto;
    height: 100%;
}

.work-item .badge {
    margin-right: 6px;
}

.work-item .link-ma {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
    transform: translateY(1px);
}