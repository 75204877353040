.navbar {
    background-color: white;
}

.navbar-brand {
    margin-right: 0px;
}

.navbar a.nav-link {
    font-weight: 700;
    position: relative;
}

    .navbar a.nav-link.disabled {
        pointer-events: auto;
    }

    .navbar a.nav-link:not(.disabled)::after {
        content: "";
        position: absolute;
        top: 3px;
        bottom: 3px;
        left: 3px;
        right: 100%;
        border-bottom: 3px rgba(0,0,0,.5) solid;
        transition: right 1s;
    }

    .navbar a.nav-link:not(.disabled):hover::after {
        content: "";
        position: absolute;
        top: 3px;
        bottom: 3px;
        left: 3px;
        right: 3px;
        border-bottom: 3px rgba(0,0,0,.9) solid;
    }

    .navbar a.nav-link:not(.disabled).active::after {
        content: "";
        position: absolute;
        top: 3px;
        bottom: 3px;
        left: 3px;
        right: 3px;
        border-bottom: 3px rgba(0,0,0,.9) solid;
    }

@media (min-width: 576px) {
    .navbar {
        padding: 24px 48px;
    }

        .navbar a.navbar-brand {
            margin: 0;
        }

        .navbar li.nav-item {
            padding: 0 8px;
        }

        .navbar a.nav-link {
            padding: 8px 16px;
        }
}
