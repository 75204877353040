body {
    margin: 56px 0;
    font-family: 'Noto Sans KR', sans-serif;
}

.app {
    max-width: 800px;
    margin: 0 auto;
}

.fixed-top {
    max-width: 800px;
    width: 100%;
    margin: 0 auto;
}

@media (min-width: 576px) {
    body {
        margin: 88px 0;
    }
}