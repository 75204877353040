.instroduce h5 {
    color: rgba(0,0,0,.6);
}

.instroduce p {
    margin-bottom: 0.2em;
}

.instroduce p span {
    font-size: 1.2em;
}

.instroduce .card {
    max-width: 700px;
    margin: 0 auto;
}

.recent-projects .row-container {
    margin: 0 12px;
}

.recent-projects .col-sm {
    padding: 0 12px;
}
