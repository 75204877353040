.link-bar {
    padding: 32px;
}

.link-bar li.nav-item {
    padding: 0 8px;
}


.link-bar a .ico-round {
    width: 32px;
    height: 32px;
    background-size: cover;
    background-position: center;
    background-color: rgba(0,0,0,.5);
    border-radius: 100%;
    transition: background-color 0.5s;
}

.link-bar a.active .ico-round {
    background-color: rgba(0,0,0,.9);
}
